/* CSS Reset for cross-platform consistency */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Root variables with fallback values */
:root {
  --primary-color: #000000;
  --link-color: #61dafb;
  --secondary-color: white;
  --border-radius: 0%;
  --spacing-unit: clamp(1rem, 2vw, 2rem);
}

/* Responsive typography */
html {
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  html {
    font-size: 14px;
  }
}

.App {
  text-align: center;
  width: 100%;
  overflow-x: hidden;
}

.App-logo {
  height: clamp(20vh, 40vmin, 40vh);
  pointer-events: none;
  max-width: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: clamp(1rem, calc(0.75rem + 1.5vw), 1.5rem);
  padding: var(--spacing-unit);
}

.App-link {
  color: var(--link-color);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sectionStyle {
  background-color: var(--secondary-color);
  padding: clamp(2rem, 5vw, 4rem) 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Responsive buttons */
.primary-button,
.secondary-button {
  background-color: transparent !important;
  border-radius: var(--border-radius) !important;
  border-width: 2px !important;
  border-style: solid !important;
  padding: clamp(0.4rem, 0.8vw, 0.8rem) clamp(0.8rem, 1.5vw, 1.5rem) !important;
  transition: all 0.3s ease !important;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
}

.primary-button {
  color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
}

.secondary-button {
  color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

/* Responsive hero text */
.hero-text {
  font-size: clamp(1.5rem, 4vw, 3rem);
  font-weight: 700;
  margin-bottom: clamp(1rem, 2vw, 2rem);
  color: #fff;
  line-height: 1.2;
  letter-spacing: -0.02em;
}

.section-container {
  margin: clamp(0.5rem, 1vw, 1rem) 0;
  padding: 0 clamp(1rem, 3vw, 2rem);
}

.footer-link {
  text-decoration: none;
  color: inherit;
  margin-bottom: clamp(0.5rem, 1vw, 1rem) !important;
  padding: 0.5rem;
  display: inline-block;
}

/* Touch device optimizations */
@media (hover: hover) {
  .primary-button:hover,
  .secondary-button:hover {
    transform: translateY(-2px);
    opacity: 0.9;
  }
}

/* Media queries for different device sizes */
@media screen and (max-width: 480px) {
  .section-container {
    padding: 0 1rem;
  }
  
  .hero-text {
    text-align: left;
  }
}

/* iOS specific fixes */
@supports (-webkit-touch-callout: none) {
  .App-header,
  .sectionStyle {
    min-height: -webkit-fill-available;
  }
}

/* Ensure smooth scrolling across all platforms */
@media screen and (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}

/* Responsive navigation styles */
@media screen and (max-width: 600px) {
  .MuiTabs-root {
    min-height: 48px !important;
  }

  .MuiTab-root {
    min-width: auto !important;
    padding: 6px 12px !important;
    font-size: 0.875rem !important;
  }

  .nav-container {
    padding: 0 8px;
  }

  .MuiToolbar-root {
    padding-left: 8px !important;
    padding-right: 8px !important;
    min-height: 56px !important;
  }
}

/* Responsive button sizes */
@media screen and (max-width: 600px) {
  .primary-button,
  .secondary-button {
    font-size: 0.875rem !important;
    padding: 6px 12px !important;
    margin: 0 4px !important;
    min-width: 80px !important;
  }
  
  .MuiButton-root {
    white-space: nowrap;
    min-width: fit-content !important;
  }
}

/* Adjust logo text size for mobile */
@media screen and (max-width: 480px) {
  .logo-text {
    font-size: 1.5rem !important;
  }
}


